import React, { Component, useState, useCallback, useEffect  } from 'react'
import { BrowserRouter,HashRouter, Routes, Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import { Container, Button, Alert } from 'react-bootstrap';
import axios from "axios";
import follow from "./images/follow.png";
import Polygon from "./polygon";
import Ethereum from './ethereum';

import './styles.css';
import Banner from "./images/banner.png";
function App() {

  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [data, setData] = useState([null]);
  const [dataPolygon, setDataPolygon] = useState([null]);
  const [gotData, setGot] = useState(false);
  
    function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const gettop50 = async () => {
    //     const resMint = await axios.get( 'http://localhost:8080/Top100?Action=Top100Polygon', {
    const resMint = await axios.get("eth100.json", {
      headers: { "Content-Type": "application/json" }
    });
    // console.log(resMint.data[0]);
    let accountMetaData = [];

    for (let i = 0; i < resMint.data.length; i++) {
      accountMetaData.push(resMint.data[i]);
    }
    const rows = accountMetaData.map((row, index) => (
      <li className="row">
        <span className="rank">
          <div className="contentDiv">
            {row.rank == 1 ? (
              <div className="imgspan">&nbsp;</div>
            ) : row.rank == 2 ? (
              <div className="imgspan2">&nbsp;</div>
            ) : row.rank == 3 ? (
              <div className="imgspan3">&nbsp;</div>
            ) : (
              <span style={{ opacity: "72%" }}>{row.rank}</span>
            )}
          </div>
        </span>
        <span className="link">
          <div className="contentDiv">
            <a target="_blank" href={"https://opensea.io/" + row.link} style={{ opacity: "72%" }}>
              {row.link.replace("https://opensea.io/", "").substring(0, 6)}...
              {row.link.replace("https://opensea.io/", "").substring(37)}
            </a>
          </div>
        </span>
        <span className="buy">
          <div className="contentDiv" style={{ opacity: "72%" }}>{row.buys}</div>
        </span>
        <span className="avgprice">
          <div className="contentDiv" style={{ opacity: "72%" }}>$ {(row.avgprice * 1).toFixed(2)}</div>
        </span>
        <span className="sold">
          <div className="contentDiv" style={{ opacity: "72%" }}>{row.sold}</div>
        </span>
        <span className="profit">
          <div className="contentDiv" style={{ opacity: "72%" }}>
            $ {numberWithCommas((row.profit * 1).toFixed(2))}
          </div>
        </span>
        <span className="roi">
          <div className="contentDiv" style={{ opacity: "72%" }}>{(row.roi * 1).toFixed(0)} %</div>
        </span>
        <span className="genesis">
          <div className="contentDiv" style={{ opacity: "72%" }}>{row.genesis}</div>
        </span>
        <span className="follow">
          <div className="contentDiv">
            <a target="_blank" href={"https://opensea.io/" + row.link}>
              <img className="followimg" src={follow} />
            </a>
          </div>
        </span>
      </li>
    ));    
    setGot(true);
    setData(rows);


  };
  
    const gettop50Polygon = async () => {
    //     const resMint = await axios.get( 'http://localhost:8080/Top100?Action=Top100Polygon', {
    const resMint = await axios.get("poly100.json", {
      headers: { "Content-Type": "application/json" }
    });
    // console.log(resMint.data[0]);
    let accountMetaData = [];

    for (let i = 0; i < resMint.data.length; i++) {
      accountMetaData.push(resMint.data[i]);
    }
    const rows = accountMetaData.map((row, index) => (
      <li className="row">
        <span className="rank">
          <div className="contentDiv">
            {row.rank == 1 ? (
              <div className="imgspan">&nbsp;</div>
            ) : row.rank == 2 ? (
              <div className="imgspan2">&nbsp;</div>
            ) : row.rank == 3 ? (
              <div className="imgspan3">&nbsp;</div>
            ) : (
              <span style={{ opacity: "72%" }}>{row.rank}</span>
            )}
          </div>
        </span>
        <span className="link">
          <div className="contentDiv">
            <a target="_blank" href={row.link} style={{ opacity: "72%" }}>
              {row.link.replace("https://opensea.io/", "").substring(0, 6)}...
              {row.link.replace("https://opensea.io/", "").substring(37)}
            </a>
          </div>
        </span>
        <span className="buy">
          <div className="contentDiv" style={{ opacity: "72%" }}>{row.buys}</div>
        </span>
        <span className="avgprice">
          <div className="contentDiv" style={{ opacity: "72%" }}>$ {(row.avgprice * 1).toFixed(2)}</div>
        </span>
        <span className="sold">
          <div className="contentDiv" style={{ opacity: "72%" }}>{row.sold}</div>
        </span>
        <span className="profit">
          <div className="contentDiv" style={{ opacity: "72%" }}>
            $ {numberWithCommas((row.profit * 1).toFixed(2))}
          </div>
        </span>
        <span className="roi">
          <div className="contentDiv" style={{ opacity: "72%" }}>{(row.roi * 1).toFixed(0)} %</div>
        </span>
        <span className="genesis">
          <div className="contentDiv" style={{ opacity: "72%" }}>{row.genesis}</div>
        </span>
        <span className="follow">
          <div className="contentDiv">
            <a target="_blank" href={row.link}>
              <img className="followimg" src={follow} />
            </a>
          </div>
        </span>
      </li>
    ));
    setDataPolygon(rows);
  };

  useEffect(() => {

   if ( ! gotData ) 
   { gettop50();  
   gettop50Polygon();   }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const switchpage = useCallback(() => {
    setShowMessage(true);
    
  }, [showMessage])
  
  const switchpage2 = useCallback(() => {
    setShowMessage(false);
    setShowButton(true);
  }, [showMessage])
  
    return (<div className="app">
          <div>
        <img src={Banner} alt="banner" style={{ width: "100%" }} />
      </div>
                
      
   <CSSTransition
        in={showButton}
        timeout={600}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowMessage(false)}
        onExited={() => setShowMessage(true)}
      >

                  <Ethereum rows={data} switchpage={switchpage} />                     
                     </CSSTransition>


      
      <CSSTransition
        in={showMessage}
        timeout={600}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
      >
      <Polygon rows={dataPolygon} switchpage={switchpage2}  />                     
      </CSSTransition>

           
                                      
                                      
            </div>
           )
     
}
export default App;

