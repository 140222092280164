import React, { useState, useEffect } from "react";
import moralis from "moralis";
import Web3 from "web3";
import "./App.css";
import axios from "axios";

import Row from "./images/row.png";
import rank1 from "./images/1st.png";
import rank2 from "./images/2nd.png";
import rank3 from "./images/3rd.png";
import follow from "./images/follow.png";
import address from "./images/Address.png";
import rank from "./images/Rank.png";
import buys from "./images/buys.png";
import avgprice from "./images/avgprice.png";
import sold from "./images/sold.png";
import profit from "./images/Profit.png";
import roi from "./images/ROI.png";
import genesis from "./images/Genesis.png";
import ethereum from "./images/eth.png";
import polygon from "./images/polygon.png";
import polygonfade from "./images/polygon-fade.png";
import solana from "./images/solana-cs.png";
import bnb from "./images/bnb.png";
import exportimg from "./images/export.png";
import dune from "./images/dune.png";
import opensea from "./images/opensea.png";
import followtitle from "./images/followtitle.png";
import moralislogo from "./images/moralis.png";
var web3 = new Web3(Web3.givenProvider);
function App(props) {

  const [data, setData] = useState([null]);
  const [usdConversion, setUsdPrice] = useState(0);
  const [gotData, setGot] = useState(false);

  return (
    <div className="App">
      <div>
        <ul className="list-align">
          <li id="toprow">
            <span id="topleft">
              <span id="polygon">
                <a onClick={props.switchpage} >
                  <img src={polygonfade} />
                </a>
              </span>
              <span id="eth">
                <img src={ethereum} alt="ethereum" />
              </span>
              <span id="solana">
                <img title="Coming Soon!" src={solana} alt="solana" />
              </span>
              <span id="bnb">
                <img title="Coming Soon!" src={bnb} alt="bnb" />
              </span>
            </span>
            <span>
              <img src={exportimg} />
            </span>
          </li>
          <li className="title row">
            <span className="rank">
              <div className="contentDiv">
                <img src={rank} />
              </div>
            </span>
            <span className="link">
              <div className="contentDiv">
                <img src={address} />
              </div>
            </span>
            <span className="buy">
              <div className="contentDiv">
                <img src={buys} />
              </div>
            </span>
            <span className="avgprice">
              <div className="contentDiv">
                <img src={avgprice} />
              </div>
            </span>
            <span className="sold">
              <div className="contentDiv">
                <img src={sold} />
              </div>
            </span>
            <span className="profit">
              <div className="contentDiv">
                <img src={profit} />
              </div>
            </span>
            <span className="roi">
              <div className="contentDiv">
                <img src={roi} />
              </div>
            </span>
            <span className="genesis">
              <div className="contentDiv">
                <img src={genesis} />
              </div>
            </span>
            <span className="follow">
              <div className="contentDiv">
                <img src={followtitle} />
              </div>
            </span>
          </li>
          {props.rows}
        </ul>
        <div id="footer">
          <img src={dune} />
          &nbsp;&nbsp;&nbsp;
          <img src={moralislogo} />
        </div>
      </div>
    </div>
  );
}

export default App;
